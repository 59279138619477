import { Fragment, memo } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Divider, theme } from 'antd'

import { PageHeader } from '@/components/PageHeader'
import { Register } from './Register'
import { SignIn } from './SignIn'
import styles from './styles.module.less'

const LoginInner = ({ pageType }: { pageType: '' | 'register' | 'passwordReset' }) => {
  const { token } = theme.useToken()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const label = (
    {
      '': t('Login.login'),
      register: t('Login.register'),
      passwordReset: t('Login.resetPassword'),
    } as const
  )[pageType]
  const links = [
    { key: '', text: t('Login.login'), link: '/login' },
    { key: 'register', text: t('Login.register'), link: '/login/register' },
    { key: 'passwordReset', text: t('Login.resetPassword'), link: '/login/passwordReset' },
  ] as const

  return (
    <div className={styles.box}>
      <PageHeader className={styles.header} />
      <div className={styles.main}>
        <div className={styles.inner}>
          <div className={styles.inner_header}>
            <div style={{ color: token.colorPrimary }} className={styles.inner_header_label}>
              {label}
            </div>
            <div className={styles.inner_header_links}>
              {links
                .filter(d => d.key !== pageType)
                .map(({ key, text, link }, index, arr) => (
                  <Fragment key={key}>
                    <Button type="link" onClick={() => navigate(link, { replace: true })}>
                      {text}
                    </Button>
                    {!!arr[index + 1] && <Divider type="vertical" style={{ margin: 0 }} />}
                  </Fragment>
                ))}
            </div>
          </div>

          {pageType === '' && <SignIn />}
          {pageType === 'register' && <Register />}
          {pageType === 'passwordReset' && <Register isReset />}
        </div>
      </div>
    </div>
  )
}

export const Login = memo(function Login() {
  const { pageType } = useParams<{ pageType: string }>()
  const { search, hash } = useLocation()

  if (pageType === undefined || pageType === 'register' || pageType === 'passwordReset') {
    return <LoginInner pageType={pageType || ''} />
  }
  return <Navigate to={{ pathname: '/login', search, hash }} replace />
})
