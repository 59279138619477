import { Fragment, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import md5 from 'md5'
import { Button, Col, Form, Input, message, Popover, Row } from 'antd'
import Icon, {
  CodeOutlined,
  ExclamationCircleOutlined,
  HistoryOutlined,
  LockOutlined,
  UserOutlined,
} from '@ant-design/icons'

import { getGlobalState } from '@/globalStore/globalStore'
import { emailValidate, formAutoTrimOnBlur } from '@/utils/utils'
import { apiEmailCode, apiSignUpOrResetCipher } from './apis'
import { passwordValidate } from './utils'
import EmailIcon from '@/assets/icons/email.svg?react'

type IValues = {
  userName: string
  email: string
  code: string
  password: string
  confirmPassword: string
}

const passwordValidator = async (v: string | null | undefined) => {
  if (!passwordValidate(v)) throw new Error(passwordValidate.getTip().replace(/\n+/, ''))
}

const getPasswordTipIcon = () => (
  <Popover
    mouseLeaveDelay={0}
    content={passwordValidate
      .getTip()
      .split(/\n+/)
      .map((d, i, arr) => (
        <Fragment key={d}>
          {d}
          {i !== arr.length - 1 && <br />}
        </Fragment>
      ))}
    placement="top"
  >
    <ExclamationCircleOutlined style={{ marginLeft: 4 }} />
  </Popover>
)

export const Register = (props: { isReset?: boolean }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const isReset = useRef(!!props.isReset).current

  const initialSearchEmail = useRef(searchParams.get('email')).current
  const initialEmail = isReset && emailValidate(initialSearchEmail) ? initialSearchEmail : null
  const initialValues: IValues = {
    userName: '',
    email: initialEmail || '',
    code: '',
    password: '',
    confirmPassword: '',
  }

  const [form] = Form.useForm<IValues>()
  const [loading, setLoading] = useState(false)
  const [readOnly, setReadOnly] = useState(true) // hack: 禁用自动填充

  const emailErr = !emailValidate(Form.useWatch(v => v.email, form))
  const [countdown, setCountdown] = useState(0)
  const timerRef = useRef(0)

  // 清除 url 参数
  useEffect(() => {
    if (initialSearchEmail) setSearchParams(undefined, { replace: true })
  }, [initialSearchEmail, setSearchParams])

  useEffect(() => {
    clearTimeout(timerRef.current)
    if (countdown > 0) {
      timerRef.current = window.setTimeout(() => setCountdown(prev => prev - 1), 1000)
    }
    return () => clearTimeout(timerRef.current)
  }, [countdown])

  useEffect(() => {
    let id = window.setTimeout(() => {
      id = 0
      setReadOnly(false) // 兜底
    }, 1000)
    return () => void (id && window.clearTimeout(id))
  }, [])

  return (
    <Form
      form={form}
      initialValues={initialValues}
      layout="vertical"
      onFocusCapture={({ target }) => {
        if (target.readOnly) target.readOnly = false
        if (readOnly) setReadOnly(false)
      }}
      onBlur={e => formAutoTrimOnBlur({ event: e, form, excludeField: ['password', 'confirmPassword'] })}
    >
      {!isReset && (
        <Form.Item label={t('Login.userName')} name="userName" rules={[{ required: true }]}>
          <Input prefix={<UserOutlined />} placeholder={t('Login.placeholder')} allowClear readOnly={readOnly} />
        </Form.Item>
      )}
      <Form.Item
        label={t('2-common.you-xiang')}
        name="email"
        rules={[{ required: true }, { pattern: emailValidate.emailReg, message: t('Login.incorrectEmailFormat') }]}
      >
        <Input
          prefix={<Icon component={EmailIcon} />}
          placeholder={t('Login.placeholder')}
          allowClear
          readOnly={readOnly}
        />
      </Form.Item>

      <Form.Item label={t('Login.code')} required>
        <Row gutter={8}>
          <Col span={14}>
            <Form.Item noStyle label={t('Login.code')} name="code" rules={[{ required: true }]}>
              <Input
                prefix={<CodeOutlined />}
                placeholder={t('Login.placeholder')}
                allowClear
                autoComplete="off"
                readOnly={readOnly}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Button
              block
              type="primary"
              ghost
              icon={countdown > 0 && <HistoryOutlined style={{ marginRight: 6 }} />}
              disabled={countdown > 0 || emailErr}
              onClick={async () => {
                try {
                  setCountdown(60)
                  await apiEmailCode({
                    email: form.getFieldValue('email'),
                    source: isReset ? 2 : 1,
                  })
                  // 临时处理
                  message.success(
                    getGlobalState().lang === 'zh'
                      ? `验证码已发送至您的${form.getFieldValue('email')}邮箱`
                      : `Verification code has been sent to your ${form.getFieldValue('email')} email`,
                  )
                } catch (err) {
                  setCountdown(0)
                  throw err
                }
              }}
            >
              {countdown > 0 ? countdown : t('Login.getCode')}
            </Button>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item
        label={isReset ? t('Login.newPassword') : t('Login.password')}
        name="password"
        tooltip={{ icon: getPasswordTipIcon() }}
        validateTrigger="onBlur"
        rules={[{ required: true }, { validator: async (_r, v) => v && passwordValidator(v) }]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder={t('Login.placeholder')}
          allowClear
          autoComplete="off"
          readOnly={readOnly}
        />
      </Form.Item>

      <Form.Item
        label={isReset ? t('Login.confirmNewPassword') : t('Login.confirmPassword')}
        name="confirmPassword"
        validateTrigger="onBlur"
        rules={[
          { required: true },
          {
            validator: async (_r, v) => {
              if (!v) return
              await passwordValidator(v)
              if (v !== form.getFieldValue('password')) throw new Error(t('Login.passwordsNotMatch'))
            },
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder={t('Login.placeholder')}
          allowClear
          autoComplete="off"
          readOnly={readOnly}
        />
      </Form.Item>

      <Form.Item style={{ marginTop: 40 }}>
        <Button
          size="large"
          type="primary"
          block
          loading={loading}
          onClick={async () => {
            try {
              let values = await form.validateFields()
              values = form.getFieldsValue(true)
              setLoading(true)
              await apiSignUpOrResetCipher(
                {
                  ...values,
                  password: md5(values.password),
                  confirmPassword: md5(values.password),
                },
                isReset,
              )
              navigate(`/login?email=${values.email}`, { replace: true })
            } catch (err) {
              setLoading(false)
              throw err
            }
          }}
        >
          {isReset ? t('Login.resetPassword') : t('Login.registerNow')}
        </Button>
        {!isReset && (
          <div style={{ textAlign: 'right', marginTop: 6 }}>
            {t('Login.haveAccount')}
            <Button type="link" onClick={() => navigate('/login', { replace: true })}>
              {t('Login.login')}
            </Button>
          </div>
        )}
      </Form.Item>
    </Form>
  )
}
