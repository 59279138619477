import { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import md5 from 'md5'
import { Button, Form, Input, notification } from 'antd'
import Icon, { LockOutlined } from '@ant-design/icons'

import { emailValidate, formAutoTrimOnBlur } from '@/utils/utils'
import { apiLogin } from './apis'
import EmailIcon from '@/assets/icons/email.svg?react'

type IValues = { email: string; password: string }

export const SignIn = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const [form] = Form.useForm<IValues>()
  const [loading, setLoading] = useState(false)
  const initialSearchCode = useRef(searchParams.get('code')).current
  const initialSearchEmail = useRef(searchParams.get('email')).current
  const initialEmail = emailValidate(initialSearchEmail) ? initialSearchEmail : null

  // 清除 url 参数
  useEffect(() => {
    if (initialSearchCode || initialSearchEmail) setSearchParams(undefined, { replace: true })
  }, [initialSearchCode, initialSearchEmail, setSearchParams])

  useEffect(() => {
    if (initialSearchCode === '1002') {
      const key = `SignIn_${Date.now()}`
      notification.error({
        key,
        message: t('Login.loginElsewhere'),
        placement: 'top',
        duration: 0,
      })
      return () => notification.destroy(key)
    }
  }, [initialSearchCode, t])

  return (
    <Form
      form={form}
      initialValues={{ email: initialEmail || '', password: '' }}
      layout="vertical"
      onBlur={e => formAutoTrimOnBlur({ event: e, form, includeField: ['email'] })}
      onFinish={async values => {
        try {
          setLoading(true)
          await apiLogin({ ...values, password: md5(values.password) })
          navigate(import.meta.env.VITE_APP_HOME_ROUTE_PATH, { replace: true })
        } catch (err) {
          setLoading(false)
          throw err
        }
      }}
    >
      <Form.Item
        label={t('2-common.you-xiang')}
        name="email"
        rules={[{ required: true }, { pattern: emailValidate.emailReg, message: t('Login.incorrectEmailFormat') }]}
      >
        <Input prefix={<Icon component={EmailIcon} />} placeholder={t('Login.placeholder')} allowClear />
      </Form.Item>

      <Form.Item
        label={t('Login.password')}
        extra={
          <Button
            style={{
              position: 'absolute',
              right: 0,
              top: 2,
              padding: 0,
              transform: 'translateY(-100%)',
            }}
            type="link"
            onClick={() => {
              const email = form.getFieldValue('email')
              let search = ''
              if (emailValidate(email)) search = `?email=${email}`
              navigate(`/login/passwordReset${search}`, { replace: true })
            }}
          >
            {t('Login.forgotPassword')}
          </Button>
        }
        name="password"
        rules={[{ required: true }]}
      >
        <Input.Password prefix={<LockOutlined />} placeholder={t('Login.placeholder')} allowClear />
      </Form.Item>

      <Form.Item style={{ marginTop: 5 }}>
        <Button size="large" type="primary" block htmlType="submit" loading={loading}>
          {t('Login.login')}
        </Button>
        <div style={{ textAlign: 'right', marginTop: 6 }}>
          {t('Login.haveNoAccount')}
          <Button type="link" onClick={() => navigate('/login/register', { replace: true })}>
            {t('Login.register')}
          </Button>
        </div>
      </Form.Item>
    </Form>
  )
}
