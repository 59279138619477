import { t } from 'i18next'

export const passwordValidate = Object.assign(
  (value: string | null | undefined) => {
    if (typeof value !== 'string') return false // 不是字符
    if (/\s/.test(value)) return false // 有空格

    let typeCount = 0
    if (/\d/.test(value)) typeCount++ // 数字
    if (/[a-zA-Z]/.test(value)) typeCount++ // 字母
    if (/[^\da-zA-Z]/.test(value)) typeCount++ // 特殊字符
    if (typeCount < 2) return false // 小于两种类型

    if (value.length < 6) return false // 长度不够

    return true
  },

  {
    getTip: () => t('Login.passwordStrength'),
  },
)
