import axios from 'axios'

import './__mock__'

export const apiLogin = async (reqData: { email: string; password: string }) => {
  await axios.post('/linkhub_oms/login', reqData)
}

export const apiEmailCode = async (reqData: {
  /** 1:注册 | 2:密码重置 */
  source: 1 | 2
  email: string
}) => {
  await axios.post('/linkhub_oms/email/code', reqData)
}

export const apiSignUpOrResetCipher = async (
  reqData: {
    /** 注册必传 */
    userName?: string
    email: string
    password: string
    confirmPassword: string
    code: string
  },
  isReset?: boolean,
) => {
  let api = `/linkhub_oms/sign/up`
  if (isReset) {
    api = `/linkhub_oms/reset/cipher`
    reqData = { ...reqData }
    delete reqData.userName
  }
  await axios.post(api, reqData)
}
